import axios, { AxiosInstance, Method } from 'axios';
import { Toast } from 'vant';
export class Config {
    BaseUrl;
    service;
    constructor() {
        //this.BaseUrl = 'http://disk.crd.cn:8811'; // 正式
        this.BaseUrl = 'https://disk-qas.crd.cn'; // 本地调试 发布请注释
        this.service = axios.create({
            baseURL: this.BaseUrl,
            timeout: 20000,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        });
        this.Init();
    }
    IsLoal() {
        return true;
    }
    async Init() {
        // 添加请求拦截器
        this.service.interceptors.request.use(
            (request) => {
                // 在发送请求之前做些什么
                return request;
            },
            (error) => {
                // 对请求错误做些什么
                return Promise.reject(error);
            }
        );

        // 添加响应拦截器
        this.service.interceptors.response.use(
            (response) => {
                if (response.status == 200) {
                    let result = response.data
                    return {
                        Success: result.IsSucess,
                        Message: result.Msg,
                        Data: result.Value
                    };
                } else {
                    return {
                        Success: false,
                        Message: result.Msg,
                        Code: response.status
                    };
                }
            },
            (error) => {
                // 对响应错误做点什么
                Toast.fail({ message: error.message, duration: 3000 });
                return Promise.reject(error);
            }
        );
    }

    /**
     * 请求数据
     * @param url 服务器 URL
     * @param params url参数
     * @param data 请求主体被发送的数据
     * @param method 方法类型(GET,POST,UPDATE,DELETE)
     */
    request(url, method, params, data) {
        return this.service.request({ url, method, params, data });
    }
}