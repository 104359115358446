import Vue from 'vue'
import VueRouter from 'vue-router'
import { App, User } from 'italk-wapsdk'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'WaitingSigned',
    component: () => import('@/views/WaitingSigned.vue'),
    meta: {
      title: '待签署文件'
    }
  },
  {
    path: '/SignatureFile',
    name: 'SignatureFile',
    component: () => import('@/views/SignatureFile.vue'),
    meta: {
      title: '阅读并签署文件'
    }
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach(async (to, from, next) => {
  try  
  {
    App.SetTitle(to.meta.title)
  } 
  catch(e){}
  
  let token = localStorage.getItem('oldDiskToken')
  if (to.path == '/') {
    next();
  } else {
    if (token) next()
    else next("/");
  }
});
export default router
