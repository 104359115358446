import { Config } from '@/api/config';
import axios, { AxiosInstance, Method } from 'axios';
import { User } from 'italk-wapsdk'
export class Folder extends Config {
    BaserPath;
    UserInfo;
    Token;
    constructor() {
        super();
        this.BaserPath = '/diskClient/Folder/';
        this.UserInfo = JSON.parse(localStorage.getItem('folder_UserInfo'));
    }
    async GetToken() {
       // this.UserInfo = (await User.GetUserInfo()).Data
        this.UserInfo = {"Id":"5c768ddf19b0e36f503fc720","NickName":"彭先远","TrueName":"","Pinyin":"","PinyinFull":"","Sex":1,"Phone":"15387513422","Email":"","DeptName":"","HeadPic":"https://gz.bcebos.com/v1/hone-chat/userHead/chatfile/2019-02-27/21-37-56-854/9794191412943551.png","HeadBackgroundColor":"#d3a027","EmployeeTypeCode":"","BigDeptName":"","DeptFullName":"","ECode":"U000108095","ExtAttr":{"部门":"云企合一/CRM系统科","RelatedPublicAccount":"U000113830","ECode":"U000108095","ShopCode":"","IsMdian":"False","UserGroupCode":"","TenantID":"T0001"},"HrUserInfo":{"UserID":"U000008095","UserName":"彭先远","TenantID":"608a938672dbde455feed8b4"}};

        
        localStorage.setItem('oldDiskToken','eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJPYmoiOiJ7XCJVc2VyTmFtZVwiOlwi5b2t5YWI6L-cXCIsXCJVc2VySURcIjpcIlUwMDAwMDgwOTVcIn0iLCJTY29wZUFwcGxpY2F0aW9uIjpbIiJdLCJFeHBpcmF0aW9uU2Vjb25kIjoyNTkyMDAsIkVmZmVjdFRpbWUiOjE3MDEyMjkxNjgsIkV4cGlyYXRpb25UaW1lIjoxNzAxNDg4MzY4fQ.7VQA6aCoQsdj27E6lt-AkBIOlpS8qJ2ng38palSJ0S8mGY9aBOQ0_fTu98YKkyWfPT3Ma1wE9MSWLCdRPjuAHw');
        localStorage.setItem('folder_UserInfo', JSON.stringify(this.UserInfo))
        let service = axios.create({
            baseURL: this.BaseUrl,
            timeout: 20000,
        });
        let url = 'http://121.201.110.194:16180/diskServe/Oauth/IMOauth';
        let param = {
            scanUserid: this.UserInfo.HrUserInfo.UserID,
            token: (await User.GetToken()).Data
        }
        let res = await service.request({ url, method: 'POST', data: param });
        if (res.status == 200) {
            let Data = res.data.Data
            let token = Data.oldDiskToken
            localStorage.removeItem('oldDiskToken')
            localStorage.setItem('oldDiskToken', token)
        }
    }
    GetWaitSignListByUserID() {
        const Token = localStorage.getItem('oldDiskToken');
        const url = this.BaserPath + 'GetWaitSignListByUserID'
        let { UserID, UserName } = this.UserInfo.HrUserInfo
        const param = {
            UserID,
            UserName,
            AccessToken: Token
        };
        return this.request(url, 'POST', {}, param);
    }
    SubmitFileSign(SignID, SignLeft, SignTop, HandwritingImages, SignImgBase64Value, SignDataJson, FileSource) {
        const Token = localStorage.getItem('oldDiskToken');
        const url = this.BaserPath + 'SubmitFileSign'
        let { UserID, UserName } = this.UserInfo.HrUserInfo
        const param = {
            UserID,
            UserName,
            SignID,
            HandwritingImages,
            SignImgBase64Value,
            SignLeft,
            SignTop,
            SignDataJson,
            AccessToken: Token
            ,FileSource 
        };
        return this.request(url, 'POST', {}, param);
    }
    GetBosFileUrl(Url) {
        const Token = localStorage.getItem('oldDiskToken');
        const url = this.BaserPath + 'GetBosFileUrl'
        const param = {
            Url,
            AccessToken: Token
        };
        return this.request(url, 'POST', {}, param);
    }
}
export default new Folder();