<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
import Folder from '@/api/api'
import { App } from 'italk-wapsdk'
export default {
  components: {},
  data() {
    return {
    }
  },
  mounted() {
    App.RegistAppBack(() => {
      let router = this.$router
      if (router.currentRoute.fullPath == "/") {
        App.Close()
      } else {
        router.go(-1)
      }
    })
    this.$nextTick(async () => {
      await Folder.GetToken()
    });
  },

}
</script>
<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
